html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: "Made Tommy", cursive; /* Set global font */
}

@font-face {
  font-family: "Made Tommy";
  src: url("./MADE TOMMY Regular_PERSONAL USE.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.form-container {
  width: 70%; /* Use percentage for better responsiveness */
  max-width: 600px;
  background-color: #ffffff;
  /* background-image: url("./pictures/bg4.jpg"); */
  /* background-size: cover; */
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  padding: 20px;
  padding-bottom: 10px;
  margin: 30px auto; /* Center the container with margin auto and add space */
  position: relative;
}

@media (max-width: 800px) {
  .form-container {
    margin-top: 10px;
    top: 0;
    width: 90%;
    min-height: 85vh;
  }
}
.formRow {
  max-height: 50vh !important;
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .formRow {
    margin-top: 0px;
    max-height: 60vh !important;
  }
}

.quiz-title {
  text-align: center;
  color: #e64a19;
  font-size: 2.5em;
}

.animated-form {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column */
  grid-gap: 20px;
  width: 100%;
}

/* Responsive adjustments */
@media (min-width: 600px) {
  .animated-form {
    grid-template-columns: repeat(
      auto-fill,
      minmax(300px, 1fr)
    ); /* Use grid on larger screens */
  }
}

.question-container {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.question-label {
  font-weight: bold;
  color: #333;
  font-size: 1em;
  margin-bottom: 10px;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  border-radius: 25px;
  background-color: #f9f9f9;
  padding: 2px;
  flex-direction: column;
}

.input-field {
  width: 100%;
  border: none;
  border-radius: 25px;
  padding: 5px;
  padding-left: 20px;
  font-size: 1.1em;
  background-color: #e1f5fe;
  transition: box-shadow 0.3s, background-color 0.3s;
}

.input-field:focus {
  background-color: #ffffff;
  outline: none;
  box-shadow: 0 0 10px rgba(56, 142, 60, 0.5);
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  padding: 10px;
  background-color: #f9f9f9;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 1.1em;
  color: #1976d2;
  padding: 10px;
  border-radius: 25px;
  background-color: #ffffff;
}

.option input {
  margin-right: 10px;
}

.option:hover {
  transform: scale(1.05);
  background-color: #e0f7fa;
}

.option input:checked + span {
  color: #4caf50;
  font-weight: bold;
}

.next-button {
  padding: 6px;
  border: none;
  border-radius: 5px;
  background-color: #1b5206;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: bold;
  font-size: 1em;
  margin-top: 5px;
}

.next-button:active {
  transform: scale(0.95);
}

.progress-indicator {
  /* font-size: 2vw; Base size based on viewport width */
  text-align: left; /* Center the text */
  margin: 20px 0; /* Margin for spacing */
  color: #333; /* Text color */
  display: flex;
  flex-direction: column;
}
.progress-indicator > .text1 {
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .progress-indicator {
    /* margin-top: 40px; */
    font-size: 2vw; /* Increase font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .progress-indicator {
    font-size: 4vw; /* Further increase font size for very small screens */
  }
}

.top-corner-image {
  position: absolute; /* Position relative to the nearest positioned ancestor */
  top: 0; /* Align to the top of the container */
  left: 0; /* Align to the left of the container */
  width: 100%; /* Make the image take the full width of the container */
  height: 150px; /* Set a fixed height of 150px */
  z-index: 10; /* Ensure it stays above other content */
  object-fit: fill; /* Allow the image to stretch and fill the dimensions */
}

.error-message {
  color: red;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  padding-left: 10px;
}

/* Optional: Adjust margin for larger screens if needed */

.terms-and-submit-container {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Space between the checkbox text and button */
  margin-top: 20px; /* Add space above */
}

.terms-and-conditions {
  display: flex;
  align-items: center;
}

.terms-and-conditions input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and text */
}

.submit-button-wrapper {
  margin-left: 20px; /* Add space between the text and button if needed */
}

.parent-container {
  position: relative;
  overflow: hidden; /* Prevents overflow */
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  display: flex; /* Flexbox for centering content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background: url("./pictures/bg4.jpg") no-repeat center center; /* Center the image */
  background-size: cover; /* Cover the entire container */
}

.parent-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit; /* Inherit the background */
  filter: blur(4px); /* Adjust the blur intensity */
  z-index: 0; /* Position behind the content */
}

.content {
  position: relative; /* Position for the content */
  z-index: 1; /* Ensure content is above the blur */
  color: white; /* Text color */
  text-align: center; /* Center text */
  padding: 20px; /* Add padding for spacing */
  max-width: 90%; /* Max width for responsive behavior */
}
.bottom-right-message {
  position: fixed;
  bottom: 0px;
  left: 5px;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  /* padding: 10px 20px; */
  /* border-radius: 5px; */
  font-family: "Made Tommy", sans-serif;
  z-index: 999;
  font-size: 14px;
  max-width: 300px;
  word-wrap: break-word;
}
